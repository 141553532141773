<template>
  <component
    :is="component"
    :session-id="sessionId"
    @game-session-done="onGameSessionDone" />
</template>

<script>
import upperFirst from 'lodash-es/upperFirst';
import gameHelper from '@/modules/games-shared/game-helper';
import * as types from '@/store/mutation-types';

export default {
    props: {
        gameId: {
            type: String,
            required: true
        },
        levelIndex: {
            type: Number
        },
        sessionId: {
            type: String,
            required: true
        }
    },
    computed: {
        component() {
            return () => import('@/modules/game-' + this.gameId + '/App');
        }
    },
    methods: {
        onGameSessionDone(signal) {
          const session = gameHelper.sessions[this.sessionId];
          const isLevelSession = this.levelIndex !== undefined;

          if (signal === 'success' && session.quizReport) {
            this.$store.commit('moduleMemogenius/' + types.MEMOGENIUS_SUCCESS, {
              gameId: this.gameId,
              levelIndex: this.levelIndex,
              testName: session.testName,
            });
          }

            if (session.quizReport) {
                if (signal === 'success') {
                    if (isLevelSession && session.quizReport.stars > 0) {
                        this.$store.dispatch(`moduleGame${upperFirst(this.gameId)}/updateLevel`, {
                            levelIndex: this.levelIndex,
                            data: {
                                stars: session.quizReport.stars,
                                bestTime: session.quizReport.timeUsed
                            }
                        });
                    }
                }
                if (session.challengeId) {
                    this.$store.dispatch('moduleSocial/createActivity', { challengeId: session.challengeId });
                }

                if (session.testName) {
                    this.$store.dispatch('moduleMemogenius/storeMemoryOnboardingResult', {
                        testName: session.testName,
                        data: {
                            totalCount: session.options.numberOfItems,
                            correctCount: session.quizReport.items.filter(item => item.score === 1).length,
                            timeUsed: session.quizReport.timeUsed + (session.memorizeReport.timeUsed || 0)
                        }
                    });
                }
            }

            if (signal === 'exit') {
                if (session.exitUrl) {
                    return this.$router.push(session.exitUrl);
                } else if (isLevelSession) {
                    return this.$router.push({
                        name: 'MemogeniusGame-Levels'
                    });
                }
            } else if (signal === 'success') {
                if (session.resultUrl) {
                    return this.$router.push(session.resultUrl);
                } else if (isLevelSession) {
                    return this.$router.push({
                        name: 'MemogeniusGame-LevelSuccess',
                        params: {
                            levelIndex: this.levelIndex,
                            sessionId: this.sessionId
                        }
                    });
                }
            } else if (signal === 'fail') {
                if (session.resultUrl) {
                    return this.$router.push(session.resultUrl);
                } else if (isLevelSession) {
                    return this.$router.push({
                        name: 'MemogeniusGame-LevelFail',
                        params: {
                            levelIndex: this.levelIndex,
                            sessionId: this.sessionId
                        }
                    });
                }
            }

            return this.$router.push({
                name: 'DashboardHomeView'
            });
        }
    }
};
</script>
